.dd-header {
    position: relative;
    background-color: rgb(11, 26, 49); /*rgb(48, 142, 232)*/;
    z-index: 1;
}

.dd-header:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(11, 26, 49, 1))/* rgba(48, 142, 232, 1)*/, url('./patternAbeja.png');
    background-repeat: repeat;
}